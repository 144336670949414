const lang = {
    "en": {
        "normal": "Normal",
        "all_wilds": "All Wilds!",
        "debug": "Debug",

        "off": "Off",
        "matching": "Matching",
        "any": "Any"
    }
}

export default lang;